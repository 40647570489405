* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
}
optgroup {
  font-weight: 700;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
a {
  color: #337ab7;
  text-decoration: none;
}
a.btn:hover {
  text-decoration: none;
}
a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}
[role="button"] {
  cursor: pointer;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-wide {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.text-align-center {
  text-align: center;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0;
}
@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0;
  }
}
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}
.clearfix:after,
.container-fluid:after,
.container:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
}
.affix {
  position: fixed;
}
@font-face {
  font-family: Brown-Light;
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-light.woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Brown-Bold;
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-bold.woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Brown-Regular;
  src: url("https://static-cache.cocunat.workers.dev/?image=https%3A%2F%2Fglobal.cocunat.com%2Ffonts%2Flineto-brown-pro-regular.woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
.font-brown-light {
  font-family: Brown-Light;
}
.font-brown-regular {
  font-family: Brown-Regular !important;
}
.font-brown-bold {
  font-family: Brown-Bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-family: Brown-Regular;
}
body {
  font-family: Brown-Light;
  padding-top: 50px;
}
body.promo-on {
  padding-top: 95px;
}
.custom-select {
  background: #fff;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.custom-select-value {
  cursor: pointer;
  padding: 0 10px;
  position: relative;
}
.custom-select-value i {
  position: absolute;
  top: 0;
  right: 10px;
}
.custom-select img {
  margin-right: 7px;
}
.custom-select ul {
  list-style-type: none;
  background: #fff;
  line-height: 35px;
  padding: 0;
  margin: 0;
  margin-top: -2px;
  display: none;
  z-index: 2;
  position: relative;
}
.custom-select:hover > ul {
  display: block;
}
.custom-select li {
  border-top: 1px solid #ccc;
  cursor: pointer;
  padding: 0 10px;
}
.button {
  text-align: center;
  display: inline-block;
}
.button.primary,
button.primary {
  background-color: #ff8172;
  border-color: transparent;
  color: #fff !important;
}
.button.black,
button.black {
  background-color: #000;
  border-color: transparent;
  color: #fff !important;
}
.button.transparent,
button.transparent {
  background-color: transparent;
  border: 1px solid #000;
  color: #000 !important;
}
.button.default,
button.default {
  background-color: transparent;
  border: 1px solid #000;
  color: #000 !important;
}
.button.regular,
button.regular {
  height: 42px;
  padding: 0 15px;
}
.button.big,
button.big {
  height: 54px;
  padding: 0 30px;
  text-transform: uppercase;
}
.button.wide,
button.wide {
  width: 100%;
}
button.outlined,
.button.outlined {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 0%;
}
header {
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}
#open-menu-mobile {
  width: 60px;
  height: 40px;
  position: absolute;
  left: 0;
  background: 0 0;
  border: none;
}
header > .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  position: relative;
}
#cart-header-number {
  position: absolute;
  top: 20px;
  width: 23px;
  height: 23px;
  display: block;
  background: #ff8172;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 23px;
  right: -10px;
}
.container-small {
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding-right: 15px;
  padding-left: 15px;
}
.input-group {
  margin: 5px 0;
}
.input-group.component label {
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  font-family: Brown-Bold;
}
.input-group small {
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
.input-group input,
.input-group select,
textarea {
  display: block;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  height: 45px;
  color: #737373;
  line-height: 45px;
  padding: 0 10px;
  width: 100%;
  background: #fff;
}
textarea {
  height: auto;
}
.input-group input[type="checkbox"] {
  height: 20px;
  line-height: 20px;
  width: 20px;
  display: inline-block;
}
.alert {
  padding: 15px;
  border: 1px solid #2196f3;
  background: #7ebdf8;
  margin: 20px 0;
  border-radius: 5px;
}
.alert-danger {
  border-color: red;
  background: #f44336;
  color: #fff;
}
.alert-success {
  border-color: #c5e1a5;
  background: #c5e1a5;
  color: #000;
}
.alert-warning {
  border-color: #ffc2c3;
  background: #ffc2c3;
  color: #000;
}
a {
  color: #ff8172;
}
body.intro {
  background: #ffc2c3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body.intro .container {
  text-align: center;
}
body.intro h1 {
  max-width: 500px;
  margin: auto;
}
body.intro h1 i {
  font-style: italic;
  font-family: Brown-Regular;
}
.showMoreButton p {
  margin: 10px 0;
}
#hero {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #0e0d13;
  background-size: contain;
  height: 200px;
}
#hero.fallback {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #ffd1de;
  background-size: contain;
  height: 200px;
}

.desktop {
  display: none;
}
.mobile {
  display: block;
}

#category-bar {
  padding: 30px 0 10px 0;
}
#category-bar a.active span {
  font-family: Brown-Bold;
}
#search-bar {
  padding: 0 0 30px 0;
  border-bottom: 1px solid #ececec;
}
#search-bar .input {
  position: relative;
}
#search-bar .input input {
  width: 100%;
  padding: 0 45px 0 10px;
  height: 45px;
  border: 1px solid #aaa;
}
#search-bar .input button {
  position: absolute;
  top: 0;
  right: 10px;
  background: #ffbcbf;
  font-size: 24px;
  color: #fff;
  height: 45px;
  padding: 0 15px;
  line-height: 10px;
  border: 1px solid #aaa;
}
#category-bar a {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000;
  text-decoration: none;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  height: 125px;
}
#category-bar a img {
  margin-bottom: 10px;
  height: 65px;
  width: 65px;
}
.product-item {
  text-align: center;
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
.product-list.container.search {
  padding-left: 0;
  padding-right: 0;
}
.product-item button {
  padding: 0 5px !important;
  font-family: Brown-Bold;
}
.product-item a {
  color: #000;
  text-decoration: none;
}
.product-item img {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
}
.product-item.featured img {
  display: block;
  margin: auto;
  height: auto;
  width: 100%;
}
.product-title {
  overflow: visible;
  height: 110px;
  margin: 10px 0;
  text-align: left;
}
.product-title p {
  margin: 3px 0;
}
.promo-sticker-product-loop {
  height: auto !important;
  width: 65px !important;
  position: absolute;
  top: 5px;
  left: 15px;
}

.product-tag {
  height: auto !important;
  padding: 4px 8px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  background: #000000;
  border-radius: 2px;
  gap: 4px;
  color: #ffffff;
}

.promo-tag {
  height: auto !important;
  padding: 4px 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  background: #fde9e6;
  border-radius: 2px;
  gap: 4px;
  color: #000000;
  font-family: "Brown-Regular";
}

.club-tag {
  height: auto !important;
  padding: 4px 8px;
  position: absolute;
  top: 5px;
  left: 5px;
  background: black;
  border-radius: 2px;
  gap: 4px;
  color: white;
  font-family: "Brown-Regular";
}
.product-item button.primary {
  display: block;
  width: 100%;
  margin-top: 15px;
}
.product-list {
  padding-top: 30px;
  padding-bottom: 100px;
}
.product-list a {
  color: #000;
  text-decoration: none;
}
.infographic-ingredientes {
  margin: 30px 0;
  overflow: hidden;
}
.ingredient-small p {
  font-size: 12px;
}
@media (min-width: 767px) {
  .product-item {
    text-align: center;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.halfStar {
  position: relative;
  display: inline-block;
  color: #e0e0e0;
  white-space: pre;
  margin-left: -1px;
}
.halfStar:before {
  display: block;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  content: "★";
  overflow: hidden;
  color: #767676;
}
.orderline-row {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px #ddd solid;
}
.orderline-row .orderline-content {
  width: 100%;
}
.orderline-row .subscription-details-cart {
  margin-bottom: 10px;
}
.orderline-row .inner-orderline-row {
  width: 25%;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  padding-right: 6px;
}
.orderline-row .inner-orderline-row.price {
  width: 35%;
  flex-basis: 35%;
  padding-right: 0;
}
.orderline-row img {
  height: 80px;
  max-width: 100%;
}
.orderline-row p {
  font-family: Brown-Bold;
  text-transform: uppercase;
  font-size: 14px;
}
.orderline-row .orderline-name {
  text-align: left;
  padding-right: 8px;
}
.orderline-row .controls {
  border: 1px solid #ddd;
  display: inline-flex;
  font-size: 12px;
  line-height: 27px;
  font-weight: 700;
  width: 70px;
}
.orderline-present .controls {
  justify-content: center;
}
.orderline-row .controls button {
  background-color: #fff;
  color: #000;
  border: 0;
  font-size: 21px;
  padding: 0 10px 7px 10px;
  height: 27px;
  line-height: 23px;
}
.orderline-row .controls .delete-orderline {
  margin-left: 20px;
  font-size: 12px;
  color: #757575;
  letter-spacing: 0.55px;
  cursor: pointer;
  text-transform: uppercase;
}
.orderline-row .price {
  text-align: right;
}
.orderline-row .controls.num-only {
  display: inline-block;
}
.orderline-row .controls.num-only span:first-child {
  margin-left: 30px;
  margin-right: 30px;
}
.variations-list-cart ul {
  padding: 0;
}
.variations-list-cart li {
  list-style-type: none;
  display: flex;
  margin-bottom: 2px;
}
.variations-list-cart figure {
  height: 30px;
  width: 30px;
}
.variations-list-cart figure img {
  height: 30px;
}
.cart-title-header {
  padding: 10px;
  border-color: #efefef;
  border-style: solid;
  border-width: 1px 0;
}
.cart-title-header p {
  margin: 0;
  text-align: center;
}
.totals-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #111;
  border-left: 0;
  border-right: 0px;
  cursor: pointer;
}
.totals-content p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.promo-code-input {
  position: relative;
  display: flex;
  margin-bottom: 15px;
  margin-top: 15px;
}
.promo-code-input input {
  width: 100%;
  padding: 16px;
  border: 1px solid #dbdbdb;
  width: 75%;
  display: inline-block;
}
.promo-code-input button {
  width: 25%;
  margin-left: 5px;
  text-transform: uppercase !important;
  font-size: 14px;
  height: auto;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  color: #363636;
  cursor: pointer;
  justify-content: center;
}
.promo-cart-banner {
  padding: 10px;
  text-align: center;
  border: 2px solid #fab5ab;
  border-radius: 5px;
  margin: 25px 0;
}
.promo-cart-banner .cart-image {
  width: 40px;
  height: auto;
  margin-right: 0;
  float: left;
  text-align: center;
}
.promo-cart-banner .cart-image img {
  max-width: 100%;
  height: auto;
}
#secondary-go-checkout-button {
  display: none;
}

#cart-go-checkout {
  position: fixed;
  left: 0;
  bottom: 0;
  width: calc(100% - 30px);
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 50px !important;
  z-index: 100;
}
.trustpilot-container iframe {
  position: absolute;
  top: -190px;
  left: 0;
  width: 100%;
  height: 100%;
}
.trustpilot-container {
  position: relative;
  padding-bottom: 100%;
  height: 1180px;
  overflow: hidden;
}
.stars-text {
  text-align: center;
  font-size: 24px;
}
.please-review {
  font-size: 18px;
  text-align: center;
  padding: 0 10px;
}
@media (min-width: 394px) and (max-width: 455px) {
  .trustpilot-container iframe {
    top: -215px;
  }
}
@media (min-width: 767px) {
  #secondary-go-checkout-button {
    display: block;
  }
  #cart-go-checkout {
    position: relative;
    left: unset;
    bottom: unset;
    width: 100%;
    margin-left: unset !important;
    margin-right: unset !important;
    margin-bottom: unset !important;
  }
}
.checkout-preview-address {
  padding: 16px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  margin: 45px 0 20px 0;
}
.checkout-preview-address p {
  margin: 0;
  color: #737373;
}
#checkout-addresses-list {
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 10px 20px;
  margin-bottom: 30px;
}
#checkout-addresses-list .radio-button {
  border-bottom: 1px solid #eaeaea;
  height: 50px;
  justify-content: center;
}
#checkout-addresses-list .radio-button:last-child {
  border-bottom: 0;
}
#checkout-addresses-list .radio-button .circle,
#checkout-addresses-list .radio-button .circle-o {
  margin-bottom: 0 !important;
}
#subscription-password {
  margin: 40px auto;
}
.stripe-base-style {
  border: 1px solid #b5b5b5;
  padding: 0 10px;
  border-radius: 3px;
}
#add-new-address {
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
#add-new-address p {
  margin: 0;
}
#product-single #title h1 {
  font-weight: 700;
  font-family: Brown-Regular;
  font-size: 30px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
#product-single #title h2 {
  text-transform: initial;
  display: block;
  font-family: Brown-Regular;
  margin: 5px 0 7px 0;
  font-size: 20px;
  color: #000;
  font-weight: 100;
  line-height: 22px;
}
#product-single #title small {
  text-transform: initial;
  display: block;
  font-family: Brown-Regular;
  margin: 5px 0 7px 0;
  font-size: 18px;
  color: #000;
  font-weight: 100;
  line-height: 28px;
}
#product-single s {
  background: url(https://global.cocunat.com/stroke.png);
  text-decoration: none;
  background-position: center 3px;
  background-size: 4px 17px;
  background-repeat: repeat-x;
}
#product-single {
  font-size: 18px;
  text-align: left;
}
#product-single ul {
  list-style-type: none;
  padding-left: 25px;
  margin-left: 0;
}
#product-single #summary li {
  text-align: left;
  margin-bottom: 5px;
  display: flex;
}
#product-single #summary ul li:before {
  content: "";
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=36&height=34&image=https://global.cocunat.com/web/list-icon-cocunat.png);
  background-size: 11px 11px;
  background-repeat: no-repeat;
  margin-right: 7px;
  margin-top: 7px;
  min-width: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 50%;
  width: 17px;
  height: 17px;
  line-height: 17px;
  font-size: 23px;
}
#product-single.makeup {
  font-size: 21px;
}
#product-single.makeup #summary ul li:before {
  background-image: url(https://resizing.cocunat.com/r/?quality=80&format=webp&fit=contain&width=36&height=34&image=https://global.cocunat.com/c-dot.png);
  background-size: 15px 15px;
  width: 20px;
  height: 20px;
}
#product-single #summary li p {
  display: inline-block;
  margin: 0;
}
#product-single .packaging-item {
  margin-bottom: 15px;
  text-align: left;
  min-height: 36px;
  align-items: center;
  display: flex;
}
#product-single .packaging-item img {
  margin-right: 10px;
  width: 36px;
  height: auto;
}
#product-single .packaging-item span {
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
  vertical-align: middle;
  width: 90px;
  flex: 1;
}
#product-single .packaging-makeup {
  text-align: center;
  overflow: hidden;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
#product-single .packaging-makeup .packaging-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  line-height: 19px;
  width: 100px;
}
#product-single .packaging-makeup .packaging-item span {
  font-size: 16px;
  line-height: 19px;
  display: block;
  width: 90px;
  color: #f29fb5;
  display: block;
  margin-top: 3px;
  text-align: center;
}
#product-single .packaging-makeup .packaging-item img {
  margin-right: 0;
  width: 50px;
  height: auto;
}
#product-single #description h3,
#product-single .heart-title {
  font-family: Brown-Light;
  font-weight: 700;
  font-size: 27px;
  text-align: center;
  padding: 0 15px;
  line-height: 33px;
  margin-bottom: 5px;
  color: #ff8172;
}
#product-single #description .pd {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #ff8172;
  margin-top: 15px;
  font-size: 14px;
  line-height: 19px;
  align-items: flex-start;
}
#product-single #description .pd ul {
  margin: 1.5em 0;
  padding-left: 20px;
  list-style-type: disc;
}
#product-single #description .pd:last-child {
  border: 0;
}
#product-single #description .pd img {
  width: 55px;
  height: auto;
  margin-right: 20px;
  -o-object-fit: contain;
  object-fit: contain;
}
#product-single .incii-img {
  padding: 0;
  margin: 0;
}
#product-single .incii-img img {
  width: 100%;
  height: auto;
}
#product-single #how-to-use {
  padding: 30px 0;
  background-color: #5bc6d0;
  color: #fff;
  text-align: center;
}
#product-single #how-to-use.makeup {
  background: #ffe7ed;
  color: #000;
}
#product-single #how-to-use.makeup h4 {
  color: #000 !important;
  font-family: Brown-Bold !important;
}
#product-single button.primary.add-to-cart {
  position: relative;
  left: 0;
  bottom: 0;
  width: calc(100% - 30px);
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-bottom: 50px !important;
  z-index: 99;
}

body.sticky-button #product-single button.primary.add-to-cart {
  position: fixed;
}

#product-single button.primary.add-to-cart.disabled {
  background-color: #c4c3c3;
  color: #fff;
  cursor: default;
}
#product-single #ideal-for {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal-presale-content button {
  margin: 0 !important;
  font-size: 16px;
}
.modal-presale-content button.blackTxt {
  color: #000 !important;
  border: 1px solid #000;
  background-color: #fff;
}
.ideal-for-text {
  padding: 25px 20px;
  background: #fff;
  margin-top: -100px;
}
.curly-text {
  padding: 25px 20px;
  background: #fac93e;
  margin-top: -20px;
  margin-bottom: -30px;
}
#curly-container {
  background-color: #5bc6d0;
}
.product-single {
  padding-bottom: 60px;
}
#upsells {
  border: 1px solid #000;
  margin: 25px 20px;
  border-radius: 0;
  padding: 5px 20px;
}
#upsells .radio-button {
  border-bottom: 1px solid #000;
  padding: 10px 0 10px 0;
  color: #000;
  line-height: 1.35em;
}
#upsells .price-product {
  margin-left: 30px;
}
#upsells ul {
  padding-left: 30px;
}
#upsells .radio-button__inner {
  font-family: Brown-Regular;
}
#upsells .radio-button:last-child {
  border-bottom: 0;
}
#upsells .circle-o::after {
  border-color: #000;
  background-color: #000;
}
#upsells .circle-o {
  border-color: #000;
}
.gourmet-selector {
  font-size: 14px;
  font-family: Brown-Regular;
}
.gourmet-selector .circle-o::after {
  background-color: #000;
}
.gourmet-selector .circle,
.gourmet-selector .circle-o {
  border: 2px #000 solid;
  margin-bottom: 0;
}
.gourmet-selector span {
  font-size: 16px;
}
#subscription-select select {
  margin: 20px 0;
  font-size: 16px;
}
#reviews {
  padding: 40px 0;
}
#reviews #reviews-summary ul {
  list-style-type: none;
}
#reviews-summary {
  text-align: center;
}
#reviews-summary p {
  margin-top: 0;
}
#reviews-summary ul {
  padding: 0;
  width: 285px;
  margin: 40px auto;
}
#reviews-summary ul li {
  margin-bottom: 2px;
}
#reviews-summary ul span {
  width: 80px;
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
}
#reviews-summary .progress {
  display: inline-block;
  width: 150px !important;
}
#reviews-summary .number {
  width: 40px;
  display: inline-block;
  margin-right: 0;
  margin-left: 10px;
}

#reviews #add-review-form .star-add-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
#reviews #add-review-form .on {
  color: #767676;
}
#reviews #add-review-form .off {
  color: #e0e0e0;
}

#modalClub {
  margin: 0 -15px;
}
#modalClub ol {
  text-align: left;
  padding-left: 30px;
}
#modalClub li {
  border-bottom: 1px solid #00000026;
  padding: 10px 0;
  font-size: 14px;
}
#modalClub button {
  margin-bottom: 10px !important;
}
#modalClub li::marker {
  font-family: Brown-Bold;
}
#modalClub li:last-child {
  border-bottom: 0 solid #000;
}
.icon-wrapper i {
  font-style: normal;
  font-size: 24px;
}
.icon-wrapper i.aceite-abisinia:before {
  content: "\e800";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-almendras:before {
  content: "\e801";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.mica:before {
  content: "\0e80";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.glicerina:before {
  content: "\e821";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aloe:before {
  content: "\e81D";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-almendras:before {
  content: "\e801";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-chia:before {
  content: "\e802";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-coco:before {
  content: "\e803";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-girasol:before {
  content: "\e804";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-maiz:before {
  content: "\e805";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-jojoba:before {
  content: "\e806";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.crambe-abyssinica:before {
  content: "\e807";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-semillas-camelia:before {
  content: "\e808";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-semillas-camelina:before {
  content: "\e808";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-rosa-canina:before {
  content: "\e809";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-oliva:before {
  content: "\e80a";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-semilla-meadowfoam:before {
  content: "\e80b";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-soja:before {
  content: "\e80c";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.escualeno:before {
  content: "\e80d";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.manteca-karite:before {
  content: "\e80e";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-ricino:before {
  content: "\e80f";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.acido-hialuronico:before {
  content: "\e810";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.aceite-semillas-uva:before {
  content: "\e811";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.extracto-corteza-magnolia:before {
  content: "\e812";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.sitosterol:before {
  content: "\e813";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.cera-candelilla:before {
  content: "\e81b";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.marula:before {
  content: "\e81c";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.canola:before {
  content: "\e819";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.carnauba:before {
  content: "\e805";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper i.semillas:before {
  content: "\e81a";
  color: #fff;
  font-size: 30px;
  vertical-align: -webkit-baseline-middle;
}
.icon-wrapper {
  opacity: 1;
  color: #fff;
  font-family: Fontello;
  margin-right: 10px;
  color: #000;
}
.ingredient-list {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  font-size: 12px;
  line-height: 1.1;
}
#modalClub h1 {
  font-size: 30px;
  line-height: 40px;
}
#modalClub h2 {
  font-size: 20px;
  line-height: 22px;
}
@media (min-width: 767px) {
  #modalClub ol {
    text-align: left;
    padding-left: 30px;
  }
  #product-single #packaging {
    margin: 10px 50px 20px;
  }
  .product-single {
    width: 100%;
    max-width: 550px;
    margin: auto;
    padding-right: 15px;
    padding-left: 15px;
  }
  .ingredient-list {
    border-color: #000;
    font-size: 16px;
  }
  .text-wrapper {
    font-size: 21px;
  }
  ul.inciList {
    max-width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-column-gap: 8px;
    grid-row-gap: 16px;
    -moz-column-gap: 8px;
    column-gap: 8px;
    row-gap: 16px;
    margin-left: 14px;
  }
  #product-single button.primary.add-to-cart {
    position: relative;
    left: unset;
    bottom: unset;
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: unset;
  }
  body.sticky-button #product-single button.primary.add-to-cart {
    position: relative;
  }
}
@media (max-width: 480px) {
  .product-title {
    height: 170px;
    overflow: hidden;
  }
  .product-title strong {
    font-size: 14px !important;
  }
  .product-tag {
    position: absolute;
    background: #000000;
    color: #ffffff;
    font-size: 12px;
  }
}
#home-page #product-list {
  overflow: hidden;
}
#home-page .product-list-desktop {
  display: none;
}
#hero-home-desktop {
  display: none;
}
#home-page .product-list-mobile {
  margin-left: -15px;
  margin-right: -15px;
  display: block;
}
.product-list-mobile .product-item {
  margin: 0;
  padding: 0;
}
.product-list-mobile .product-title {
  height: 140px;
}
#home-page .no-nasties-desktop {
  display: none;
}
#home-page .no-nasties-mobile {
  display: block;
  background-image: url(https://resizing.cocunat.com/r/?quality=100&fit=cover&image=https://global.cocunat.com/planta-footer.png&width=450&height=600);
  background-size: cover;
  background-position: bottom;
  text-align: center;
  padding: 20px 25px 130px 15px;
  text-align: left;
}
#home-page .product-list-mobile .product-item {
  float: none;
  margin-left: 0;
}
#home-page .category-banner {
  margin-bottom: 30px;
  padding: 0;
  text-decoration: none;
}
#home-page .category-banner .content {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  color: #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
  padding-right: 10px;
}
#home-page .category-banner .content > div {
  width: 160px;
}
#home-page .category-banner span {
  font-size: 16px;
}
#home-page .category-banner p {
  font-weight: 700;
  font-size: 19px;
  line-height: 20px;
  margin-bottom: 9px;
}
#home-page .category-banner .banner-img {
  position: absolute;
  right: 20px;
  top: calc(50% - 20px);
}
#home-page .content-65 {
  background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/cara-no-xmas.jpg);
}
#home-page .content-66 {
  background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/cuerpo-no-xmas.jpg);
}
#home-page .content-67 {
  background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/cabello-no-xmas.jpg);
}
#home-page .content-80 {
  background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/cnmu-no-xmas.jpg);
}
#home-page #what-make-us-different div > div {
  margin: 10px auto;
}
#home-page .revolution img {
  width: 100%;
  height: auto;
}
#footer .list-footer li {
  margin: 2px 10px;
}
#footer .list-footer li a {
  color: #000;
}
#carousel-desktop {
  display: none;
}
#home-page h1 {
  max-width: 767px;
  font-size: 20px;
  line-height: 28px;
  font-family: Brown-Light;
  text-align: center;
  margin: 25px auto;
  display: block;
  padding: 0 20px;
}
.leadForm .input-email {
  padding-left: 10px;
}
.leadForm .input-email::-webkit-input-placeholder {
  color: #fff;
}
.leadForm .input-email:-moz-placeholder {
  color: #fff;
}
.leadForm .input-email::-moz-placeholder {
  color: #fff;
}
.leadForm .input-email:-ms-input-placeholder {
  color: #fff;
}
#wow-section p {
  font-size: 18px;
  margin-bottom: 30px;
  font-size: 20px;
  margin-bottom: 50px;
  line-height: 24px;
}
#wow-section ul {
  padding-left: 0;
  max-width: 100%;
  padding: 20px 0;
  padding-bottom: 100px;
  margin-bottom: 80px;
}
#wow-section ul li {
  margin-bottom: 20px;
  font-size: 20px;
  padding-left: 35px;
  position: relative;
}
#wow-section ul li.wow-li-short {
  margin-bottom: 20px;
  font-size: 20px;
  padding-left: 35px;
  position: relative;
  width: 65%;
}
#wow-section {
  background-image: url(https://global.cocunat.com/wow-chica.png);
  background-color: #c4e7e5;
  background-position: right calc(100% + 115px);
  background-repeat: no-repeat;
  background-size: 250px;
  padding: 49px 35px 0 35px;
}
#wow-section ul li::before {
  background-image: url(https://global.cocunat.com/wow-list.png);
  display: inline-block;
  content: "";
  vertical-align: middle;
  width: 24px;
  height: 22px;
  line-height: 22px;
  font-size: 23px;
  margin-right: 7px;
  background-size: 22px;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 3px;
}
.col-carousel {
  width: 32%;
}
.icons-header {
  padding-right: 10px;
}
.home-carousel-quote {
  vertical-align: top;
  text-align: center;
}
.home-carousel-instagram {
  vertical-align: top;
  text-align: center;
}
.home-carousel-instagram p {
  display: block;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 25px;
  padding: 10px;
  background: #fff;
  min-height: 200px;
}
.home-carousel-instagram img {
  width: 100%;
  height: auto;
}
.carousel-without-scroll,
.carousel-without-scroll > div,
.carousel-without-scroll > div > div,
.i-amphtml-scrollable-carousel-container {
  overflow-y: unset !important;
  overflow: hidden !important;
}
.home-carousel-quote p {
  display: block;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 25px;
}
.quotes-slider {
  display: block;
}
.instagram-slider-inner {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
}
.quotes-carousel {
  display: none;
}
.instagram-carousel-inner {
  display: none;
}
#home-page .no-nasties-desktop {
  display: none;
}
#home-page .revolution .revolution-text s {
  background: url(https://global.cocunat.com/stroke.png);
  text-decoration: none;
  background-position: center 3px;
  background-size: 4px 17px;
  background-repeat: repeat-x;
}
@media (min-width: 767px) {
  #hero-home-desktop {
    display: block;
  }
  #hero-home-mobile {
    display: none;
  }
  #home-page h1 {
    font-size: 30px;
    line-height: 38px;
    margin: 50px auto;
  }
  #home-page .category-banner .content {
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    padding-top: 5px;
    height: 280px;
  }
  #home-page .category-banner .content > div {
    width: auto;
  }
  #home-page .category-banner {
    padding: 0 10px;
  }
  #home-page .category-banner .banner-img {
    top: 40px;
  }
  #home-page .content-65 {
    background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/2022-facial.jpg);
  }
  #home-page .content-66 {
    background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/2022-corporal.jpg);
  }
  #home-page .content-67 {
    background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/2022-capilar.jpg);
  }
  #home-page .content-80 {
    background-image: url(https://resizing.cocunat.com/r/?quality=95&fit=contain&image=https://global.cocunat.com/photos/2022-cnmu.jpg);
  }
  #wow-section p {
    width: 50%;
    padding-top: 20px;
  }
  #wow-section {
    background-image: url(https://global.cocunat.com/wow-chica.png);
    background-color: #c4e7e5;
    background-position: 100% 150%;
    background-repeat: no-repeat;
    background-size: 400px;
    padding: 49px 35px 80px 35px;
  }
  #wow-section ul {
    padding-left: 0;
    max-width: 100%;
    padding: 20px 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  #home-page .revolution a {
    float: left;
  }
  #home-page .revolution-text {
    padding-left: 60px !important;
  }
  #home-page .no-nasties-desktop {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 1000px;
    margin: auto;
    flex-grow: 1;
    position: relative;
    margin-bottom: 20px;
    padding: 20px;
  }
  #home-page .no-nasties-desktop .image {
    flex: 1;
    max-width: 450px;
    display: flex;
    justify-content: flex-end;
  }
  #home-page .no-nasties-desktop .content {
    flex: 1;
    padding-left: 60px;
    max-width: 450px;
  }
  #home-page .revolution {
    display: flex;
    text-align: left;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
  }
  #home-page .revolution .revolution-image {
    margin: 50px 16px;
  }
  #home-page .revolution .revolution-text h1,
  #home-page .revolution .revolution-text p {
    text-align: left;
  }

  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}
#footer {
  text-align: center;
}
#footer .title {
  background: #fff;
  padding: 30px;
  font-size: 24px;
  margin-bottom: 20px;
}
#footer .pictures {
  padding: 30px 6px 30px 6px;
  background: #fdded5;
  overflow: hidden;
}
#footer .content-footer img {
  width: 100%;
  height: auto;
}
#footer .pictures .col {
  padding: 10px 5px;
  overflow: hidden;
}
#footer .pictures a {
  color: #000;
  text-decoration: none;
}
#footer div.leadForm {
  background-color: #fab5ab;
  padding: 30px 30px 35px 30px;
  font-size: 26px;
  color: #fff;
}

#footer div.leadForm .modal-screen {
  font-size: 14px;
  color: black;
}
#footer .leadForm .input-group input {
  width: 100%;
  height: 50px;
  border-radius: 0;
  border: 1px solid #fff;
  background-color: inherit;
  margin: 0;
  font-size: 16px;
  padding-right: 100px;
  color: #fff;
}
#footer .leadForm .input-group input::placeholder {
  color: #fff;
}
#footer .leadForm .input-group {
  margin: 0;
}
#footer .leadForm button.primary {
  float: right;
  margin: -49px 2px 0 0;
  background: #ff8172;
  border: none;
  color: #fff;
  padding: 12px 20px;
  font-size: 16px;
  font-family: Brown-Bold;
  position: relative;
  z-index: 9;
}

#footer .leadForm .cocunat-CheckboxGroup {
  font-size: 16px;
  color: black;
}

#footer .leadForm .cocunat-CheckboxGroup button {
  float: inherit;
  margin: 0px;
  background: none;
  border: none;
  color: black;
  padding: 0px;
  font-size: 16px;
  font-family: Brown-Light;
}

.footer-links {
  text-transform: uppercase;
}
.footer-links .title-list {
  font-size: 16px;
  margin: 0 0 5px 0;
}
.footer-links a {
  font-size: 12px;
}
#footer .list-footer {
  list-style: none;
  padding: 0 0 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: 1px solid gray;
  width: auto;
  margin: 0;
}
#footer .footer-links > div:last-child .list-footer {
  border: 0;
}
#footer .list-footer a:hover {
  text-decoration-line: underline;
}
@media (min-width: 767px) {
  #footer .list-footer {
    border: 0;
  }
  #footer .title {
    background: 0 0;
  }
}
#account {
  max-width: 450px;
  margin: auto;
  text-align: center;
  padding-top: 20px;
}
#subscriptions {
  max-width: 1000px;
  margin: auto;
  text-align: center;
  padding-top: 20px;
}
#account ul {
  list-style: none;
  padding: 0;
  margin: 35px 0 0 0;
  border-top: 1px solid #444;
}
#account ul li {
  padding: 10px 0;
  border-bottom: 1px solid #444;
}
#account ul li a {
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  display: block;
  text-decoration: none;
  font-family: Brown-Bold;
}
#account ul li a img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  transform: rotate(270deg);
  position: absolute;
  top: 0;
  right: 0;
}
#account .account-order:first-child {
  border-top: 1px solid #444;
  margin-top: 40px;
}
#account .account-order {
  padding: 5px 0;
  border-bottom: 1px solid #444;
  display: block;
  color: #000;
}
#account .account-address,
#account .account-subscriptions {
  padding: 5px 0;
  border-bottom: 1px solid #444;
  display: block;
  color: #000;
}
#account .account-address:first-child {
  border-top: 1px solid #444;
  margin-top: 40px;
}
#account .account-subscriptions:first-child {
  border-top: 1px solid #444;
}
.close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  display: block;
  background: #000;
  color: #fff;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  line-height: 30px;
  text-align: center;
  font-size: 17px;
  text-decoration: none;
  border: none;
}
.modal-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000ad;
  height: 100vh;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-container {
  width: 95%;
  background: #fff;
  max-width: 550px;
  position: relative;
  overflow-y: initial !important;
}
.modal-content {
  padding: 25px;
  max-height: 80vh;
  overflow-y: auto;
}
#menu {
  top: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
}
#menu .close {
  position: absolute;
  top: 0;
  right: 0;
  background: 0 0;
  border: 0;
  font-size: 21px;
  width: 60px;
  height: 45px;
}
#menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #ccc;
}
#menu p {
  font-size: 18px;
  padding: 0;
  text-transform: uppercase;
  margin-left: 13px;
  margin-top: 25px;
  display: block;
  font-weight: 800;
  margin-bottom: 15px;
}
#menu li {
  border-bottom: 1px solid #ccc;
  padding-right: 40px;
}
#menu li a {
  text-transform: uppercase;
  color: #333;
  padding: 20px 20px;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  font-weight: 800;
}
#menu-desktop {
  display: none;
  background: #fff;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
}
#menu-desktop ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}
#menu-desktop li {
  display: inline-block;
  margin: 0 20px;
  line-height: 31px;
}
#menu-desktop a {
  text-transform: uppercase;
  text-decoration: none;
  color: #333;
  display: block;
  font-size: 12px;
}
#header-top-bar,
#top-promo-banner {
  height: 42px;
  padding: 0 5px;
  display: flex;
  justify-content: center;
}
#header-top-bar p,
#top-promo-banner p {
  font-size: 12px;
  margin: 11px 0;
  text-overflow: clip;
  overflow: hidden;
}

.comment {
  padding: 15px 0;
}

.commentReply {
  margin-left: 30px;
  margin-bottom: 20px;
  clear: both;
  padding: 15px !important;
  background: #fff7f5;
  border-left: 5px solid #fcbdac;
}
.comment .commentTitle {
  font-size: 19px;
  text-transform: uppercase;
  margin: 3px 0px 5px 0px;
  line-height: 24px;
}
.comment .translate-link {
  color: #ff8172;
  text-decoration: underline;
  cursor: pointer;
}
.comment span {
  font-size: 19px;
  text-transform: uppercase;
  margin: 3px 0 5px 0;
  line-height: 24px;
}

.checkoutUpsellButtons {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.checkoutUpsellButtons button {
  width: inherit;
  padding: 0px 10px;
}

.acceptance-checkbox {
  margin-top: 20px;
}

@media (min-width: 767px) {
  body {
    padding-top: 80px;
  }
  body.promo-on {
    padding-top: 128px;
  }
  #header-top-bar p,
  #top-promo-banner p {
    font-size: 14px;
  }
  #hero {
    height: 300px;
  }
  #category-bar a {
    font-size: 16px;
    height: 125px;
  }
  #category-bar a img {
    height: 75px;
    width: 75px;
  }
  #carousel-desktop,
  #home-page .product-list-desktop,
  #menu-desktop,
  .instagram-carousel-inner,
  .quotes-carousel {
    display: block;
  }
  #carousel-mobile,
  #home-page .no-nasties-mobile,
  #home-page .product-list-mobile,
  #open-menu-mobile,
  .hide-desktop,
  .instagram-slider-inner,
  .quotes-slider {
    display: none;
  }
  #promises div {
    padding: 0 20px !important;
  }

  .checkoutUpsellButtons {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  #UpsellModalCheckout button {
    text-transform: none;
    font-size: 16px;
    width: 100% !important;
    margin: 5px 0px !important;
  }
}
.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeleton-line {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeleton-box::after,
.skeleton-line::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}
@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
#promises {
  display: flex;
  padding: 45px 0 10px 0;
  max-width: 500px;
  margin: auto;
}
#promises div {
  text-align: center;
  padding: 0 7px;
  color: #ff8172;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
}
.price-product {
  line-height: 24px;
  white-space: nowrap;
}
.price-product .strike-price {
  text-decoration: line-through;
  font-size: 1em;
  color: #ff8172 !important;
  font-weight: 400;
  margin-right: 5px;
}
.price-product .actual-price-with-discount {
  font-size: 1em;
  color: #ff8172 !important;
  font-weight: 400;
  margin-right: 5px;
}
.radio-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  padding: 5px 0;
}
.radio-button__inner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.circle,
.circle-o {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 100%;
  flex-shrink: 0;
}
.circle {
  border: 1px solid #444;
}
.circle-o {
  border: 1px solid #ff8172;
}
.circle-o::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #ff8172;
  display: block;
}
#product-single .carousel-single-product ul {
  padding: 0;
}
#product-single .carousel-single-product .glide__slide {
  max-width: 520px;
}
#product-single .carousel-single-product .glide-controls {
  margin-top: -70px;
  margin-bottom: 70px;
}
#product-single
  .carousel-single-product
  .glide-controls
  button.glide__bullet--active {
  background: #767676;
  border-color: #767676;
}
#product-single .carousel-single-product .glide-controls button {
  background: #f0f0f0;
  border-color: #e0e0e0;
}
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
  margin: 0;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.product-single .glide__slide {
  background: #ccc;
}
.glide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}
.glide-controls {
  text-align: center;
}
.glide-controls button {
  height: 15px;
  width: 15px;
  border: 1px solid #000;
  border-radius: 100%;
  background: 0 0;
  margin: 0 3px;
  display: inline-block;
  outline: 0 !important;
  box-shadow: none;
  -webkit-backface-visibility: hidden;
  perspective: 1px;
  padding: 0;
  box-sizing: border-box;
}
.glide-controls button.glide__bullet--active {
  background: #000;
}
.progress {
  height: 8px;
  background: #e8e8e8;
  width: 100%;
  border-radius: 3px;
}
.progress .bar {
  height: 8px;
  background: #ff8172;
  border-radius: 3px;
}
.balances {
  max-width: 450px;
  margin: auto;
  text-align: center;
  padding-top: 30px;
}
.balances table {
  width: 100%;
}
.balances table tr {
  border-bottom: 1px solid #000;
}
.balances th {
  padding: 10px 0;
}

#UpsellModalCheckout button {
  text-transform: none;
  font-size: 16px;
  width: calc(100% - 10px);
  margin: 5px 0px;
}

#UpsellModalCheckout .modal-upsell-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0px;
}

#UpsellModalCheckout .price-discounted-upsell i {
  text-decoration: line-through;
  font-style: normal;
}

#UpsellModalCheckout .price-discounted-upsell > span {
  text-decoration: none;
  padding: 5px;
  background-color: #d0392b;
  color: #fff;
  margin-left: 5px;
}

#carrier-selector {
  padding: 0 10px 0 10px;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  margin: 10px 0 25px 0;
}
#carrier-selector .radio-button .radio-button__inner {
  align-items: self-start;
}
#carrier-selector .radio-button .radio-button__inner .circle-o,
#carrier-selector .radio-button .radio-button__inner .circle {
  margin-bottom: 0px;
}
#carrier-selector .carrier-info .name {
  margin: 0;
  color: #737373;
}

#carrier-selector .radio-button {
  border-bottom: 1px solid #eaeaea;
  padding: 18px 0px;
  justify-content: center;
  display: grid;
  grid-template-columns: 6fr 0fr;
}

#carrier-selector .radio-button:last-child {
  border-bottom: 0;
}
.cocunat-CheckboxGroup {
  margin-bottom: 10px;
}

.cocunat-CheckboxGroup.error {
  color: red;
}

.cocunat-CheckboxGroup.error .CheckboxGroup_circle {
  border-color: red;
}

.cocunat-CheckboxGroup .acceptance {
  text-decoration: underline;
}

.cocunat-CheckboxGroup .checkbox-button__inner {
  display: flex;
  cursor: pointer;
}

.cocunat-CheckboxGroup .CheckboxGroup_circle,
.cocunat-CheckboxGroup .CheckboxGroup_circle-o {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 4px;
  flex-shrink: 0;
  cursor: pointer;
}
.cocunat-CheckboxGroup .CheckboxGroup_circle {
  border: 1px solid #444;
}
.cocunat-CheckboxGroup .CheckboxGroup_circle-o {
  border: 1px solid #ff8172;
  background: #ff8172
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
}
.cocunat-CheckboxGroup .CheckboxGroup_circle-o::after {
}

.cocunat-CheckboxGroup .checkbox-button__inner button {
  background: none;
  border: 0px;
  padding: 0px;
  display: inline;
}

#modal-politica-privacidad table td,
.privacy-policy-content-custom table td {
  border: 1px solid #ccc;
  padding: 5px;
}

#modal-politica-privacidad table,
.privacy-policy-content-custom table {
  width: 300px !important;
}

@media (min-width: 767px) {
  #modal-politica-privacidad table {
    width: 600px !important;
  }
  .privacy-policy-content-custom table {
    width: 600px !important;
  }
}

.react-cookie-law-manage-btn {
  background: white !important;
  border: 1px solid #000;
  color: #000 !important;
  padding: 5px !important;
}

.react-cookie-law-accept-btn {
  background: #000 !important;
  border: 1px solid #000;
  color: #fff !important;
  padding: 5px !important;
}

.react-cookie-law-container {
  padding-bottom: 5px !important;
}

.react-cookie-law-button-wrapper {
  float: right !important;
  margin-top: 10px !important;
  width: 100% !important;
}
